// extracted by mini-css-extract-plugin
export var additionalNotes = "UserInternetRfpProject_Details_RequiredProducts__additionalNotes__pZSrQ";
export var cardTitle = "UserInternetRfpProject_Details_RequiredProducts__cardTitle__S3X6E";
export var checkboxes = "UserInternetRfpProject_Details_RequiredProducts__checkboxes__g5ARd";
export var checkboxesContainer = "UserInternetRfpProject_Details_RequiredProducts__checkboxesContainer__ZMXhX";
export var column = "UserInternetRfpProject_Details_RequiredProducts__column__YX0FN";
export var descriptionHeading = "UserInternetRfpProject_Details_RequiredProducts__descriptionHeading__hO8tP";
export var flex = "UserInternetRfpProject_Details_RequiredProducts__flex__KuuTS";
export var flexColumn = "UserInternetRfpProject_Details_RequiredProducts__flexColumn__I1TKY";
export var gap1 = "UserInternetRfpProject_Details_RequiredProducts__gap1__N59yk";
export var gap2 = "UserInternetRfpProject_Details_RequiredProducts__gap2__graap";
export var gap3 = "UserInternetRfpProject_Details_RequiredProducts__gap3__Ce7Qz";
export var gap4 = "UserInternetRfpProject_Details_RequiredProducts__gap4__To3lf";
export var gap5 = "UserInternetRfpProject_Details_RequiredProducts__gap5__J6RXS";
export var headingGroup = "UserInternetRfpProject_Details_RequiredProducts__headingGroup__u07Fy";
export var headingLicenseGroup = "UserInternetRfpProject_Details_RequiredProducts__headingLicenseGroup__lorAs";
export var headingName = "UserInternetRfpProject_Details_RequiredProducts__headingName__Ju414";
export var lightBackground = "UserInternetRfpProject_Details_RequiredProducts__lightBackground__MhK3Z";
export var locationCard = "UserInternetRfpProject_Details_RequiredProducts__locationCard__oDZNP";
export var locationText = "UserInternetRfpProject_Details_RequiredProducts__locationText__lsCL0";
export var mapImage = "UserInternetRfpProject_Details_RequiredProducts__mapImage___cn7b";
export var notesContainer = "UserInternetRfpProject_Details_RequiredProducts__notesContainer__ZzSLj";
export var panel = "UserInternetRfpProject_Details_RequiredProducts__panel__c0Y4H";
export var panelBody = "UserInternetRfpProject_Details_RequiredProducts__panelBody__OfC9I";
export var panelHeading = "UserInternetRfpProject_Details_RequiredProducts__panelHeading__NrkVc";
export var productName = "UserInternetRfpProject_Details_RequiredProducts__productName__cK5L0";
export var productRow = "UserInternetRfpProject_Details_RequiredProducts__productRow__YjYQA";
export var productServiceLabel = "UserInternetRfpProject_Details_RequiredProducts__productServiceLabel__v9DEo";
export var productServiceValue = "UserInternetRfpProject_Details_RequiredProducts__productServiceValue__gQdHy";
export var productsServiceWrapper = "UserInternetRfpProject_Details_RequiredProducts__productsServiceWrapper__hDhiT";
export var propertiesContainer = "UserInternetRfpProject_Details_RequiredProducts__propertiesContainer__XsDN9";
export var propertyLeft = "UserInternetRfpProject_Details_RequiredProducts__propertyLeft__PcqP2";
export var propertyRight = "UserInternetRfpProject_Details_RequiredProducts__propertyRight__WF3k2";
export var requirements = "UserInternetRfpProject_Details_RequiredProducts__requirements__A4MYO";
export var requirementsBlock = "UserInternetRfpProject_Details_RequiredProducts__requirementsBlock__SF9JF";
export var row = "UserInternetRfpProject_Details_RequiredProducts__row__rZjQe";
export var stayShaded = "UserInternetRfpProject_Details_RequiredProducts__stayShaded__fFd1h";
export var switchLight = "UserInternetRfpProject_Details_RequiredProducts__switchLight__O8wJ3";
export var switchShaded = "UserInternetRfpProject_Details_RequiredProducts__switchShaded__QLuFp";
export var tickmarkIcon = "UserInternetRfpProject_Details_RequiredProducts__tickmarkIcon__gIRgn";
export var tileContainer = "UserInternetRfpProject_Details_RequiredProducts__tileContainer__Ew1QJ";
export var tileMap = "UserInternetRfpProject_Details_RequiredProducts__tileMap__xjrWT";
export var withColon = "UserInternetRfpProject_Details_RequiredProducts__withColon__jO2oe";