// extracted by mini-css-extract-plugin
export var colLeft = "UserInternetRfpProject_Details__colLeft__UuxlD";
export var colRight = "UserInternetRfpProject_Details__colRight____Bq9";
export var column = "UserInternetRfpProject_Details__column__b5Tj8";
export var columnWrapper = "UserInternetRfpProject_Details__columnWrapper__WAKER";
export var descriptionContent = "UserInternetRfpProject_Details__descriptionContent__ZaKoz";
export var descriptionHeading = "UserInternetRfpProject_Details__descriptionHeading__N6EdP";
export var flex = "UserInternetRfpProject_Details__flex__lzXay";
export var flexColumn = "UserInternetRfpProject_Details__flexColumn__cJz4n";
export var gap1 = "UserInternetRfpProject_Details__gap1__TUEIg";
export var gap2 = "UserInternetRfpProject_Details__gap2__jJIDd";
export var gap3 = "UserInternetRfpProject_Details__gap3__DEd8J";
export var gap4 = "UserInternetRfpProject_Details__gap4__V_NTR";
export var gap5 = "UserInternetRfpProject_Details__gap5__sN64w";
export var lastUpdated = "UserInternetRfpProject_Details__lastUpdated__szo7M";
export var reviewStatusWarning = "UserInternetRfpProject_Details__reviewStatusWarning__nrLks";
export var row = "UserInternetRfpProject_Details__row__Lyweb";