// extracted by mini-css-extract-plugin
export var column = "UserInternetRfpProject__column__sGnzH";
export var container = "UserInternetRfpProject__container__akbDF";
export var contentWrapper = "UserInternetRfpProject__contentWrapper__FliIl";
export var flex = "UserInternetRfpProject__flex__CDEKS";
export var flexColumn = "UserInternetRfpProject__flexColumn__C_anC";
export var gap1 = "UserInternetRfpProject__gap1__BvC8s";
export var gap2 = "UserInternetRfpProject__gap2__xJsn3";
export var gap3 = "UserInternetRfpProject__gap3__waRwZ";
export var gap4 = "UserInternetRfpProject__gap4__eh_Z7";
export var gap5 = "UserInternetRfpProject__gap5__wNh76";
export var row = "UserInternetRfpProject__row__IPAiu";